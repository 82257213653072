/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Poppins Fallback';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_059fbd {font-family: 'Poppins', 'Poppins Fallback';font-style: normal
}

:root {
  --background: #fff;
  --background-opacity: rgba(255, 255, 255, 0.85);
  --foreground: rgba(0, 0, 0, 0.02);
  --border: #EFF3F4;
  --heading: #0C0C1C;
  --highlight: #B2ACA7;
  --primary: #D6EF39;
  --primary-light: #D6EF39B3;
  --primary-lighter: #D6EF3933;
  --error: #FF613F;

  --border-radius: 5px;
  --ff-heading: 'Poppins';

  --fs-xsm: .875rem;
  --fs-sm: 1rem;
  --fs-md: 1.5rem;
  --fs-lg: 2.25rem;
  --fs-xlg: 2.8125rem;
  --fs-xxlg: 3.75rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--ff-heading), system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  background-color: var(--border);
  border: none;
  width: 100%;
  height: 1px
}

body {
  background: var(--background);
}

body, html {
  height: 100%;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  background-color: var(--background);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

p, strong {
  line-height: 200%;
  font-size: var(--font-size-sm);
  line-height: 170%;
}

p, strong, h1, h2, h3, h4, h5, h6 {
  color: var(--heading)
}

h1 {
  margin-bottom: 14px;
}

h2 {
  line-height: 140.5% ;
  font-size: 2.92rem;
}

.container {
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
}

.container-sm {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}


table {
  position: relative;
  border-collapse: collapse;
  width: 100%;
}

thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
}

thead tr {
  position: -webkit-sticky;
  position: sticky;
  background: var(--background);
  top: 0;
}

td {
  border-bottom: 1px solid var(--border);
  padding: 1.2rem 1rem;
  white-space: nowrap;
  font-size: 12px;
}

tbody tr:nth-child(odd) td {
  background: var(--foreground)
}

